export enum AttributeNames {
  FLICKET_USER_INTERACTION_TYPE = "flicket.user_interaction_type",
  FLICKET_USER_INTERACTION_NAME = "flicket.user_interaction_name",
  FLICKET_SESSION_ID = "flicket.session_id",
  FLICKET_ORG_ID = "flicket.org_id",
  FLICKET_FORWARD_TO_NEST = "flicket.foward_to_nest",
  FLICKET_EVENT_ID = "flicket.event_id", // The ID of the row in the "event" table. Not the same as the "event" that is being tracked.
  FLICKET_TIMEZONE = "flicket.timezone",
  FLICKET_USER_AGENT = "flicket.user_agent",
  FLICKET_COMPETITION_ID = "flicket.competition_id",
  FLICKET_EVENT_PHASE = "flicket.event_phase", // FlicketEventPhase: "registration" | "onsale";
  FLICKET_EVENT_DETAILS_PAGE_STATE = "flicket.event_details_page_state",
  HTTP_URL = "http.url",
}
