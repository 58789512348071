import React from "react";

import { Box, Flex, Divider } from "flicket-ui";
import Image from "~components/common/Image";
import EventInformation, { EventSummary } from "../events.EventInformation";
import {
  PageContext,
  isOnsaleCompetitionContext,
  isRegistrationCompetitionContext,
} from "~features/events/hooks/useDetailsPageContext";
import EventCTACard from "../events.EventCTACard";
import { competitionClosesText } from "~features/events/utils/competition";
import CompetitionDetails from "../CompetitionDetails/CompetitionDetails";
import { EventPreviewModeBanner } from "~features/events/components/EventDetails/event.PreviewModeBanner";
import CompetitionCardFooter from "../CompetitionDetails/CompetitionCardFooter";

type MobileDetailProps = {
  pageContext: PageContext;
};

export const Mobile = ({ pageContext }: MobileDetailProps) => {
  const { event } = pageContext;
  const { title, logo } = event;

  const hasRunningCompetition =
    isRegistrationCompetitionContext(pageContext) ||
    isOnsaleCompetitionContext(pageContext);

  return (
    <Flex flexDir="column" px={2} pb={5} overflowY="auto" flex="1">
      <Box flex="1">
        <EventPreviewModeBanner />

        {/* Spacer */}
        <Box height={0} width={"100%"} mt={4} />

        {logo && <Image alt={title} mb={4} image={logo} />}

        <EventSummary event={event} />

        {hasRunningCompetition ? (
          <EventCTACard
            mt={5}
            title={pageContext.competition?.title}
            image={pageContext.competition?.prizes?.[0].image}
            imageAlt={pageContext.competition?.title}
            footer={
              <CompetitionCardFooter competition={pageContext.competition} />
            }
          />
        ) : null}

        {!hasRunningCompetition && <Divider my={4} />}

        <EventInformation event={event} mb={5} />

        {hasRunningCompetition ? (
          <CompetitionDetails
            competition={pageContext.competition}
            eventId={pageContext.event?.id}
          />
        ) : null}
      </Box>
    </Flex>
  );
};
