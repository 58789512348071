import { Box, Text } from "flicket-ui";
import React from "react";
import { useWillShowPreviewCompetition } from "~features/events/hooks/usePreviewCompetition";

export const EventPreviewModeBanner = () => {
  const showPreviewCompetition = useWillShowPreviewCompetition();
  return showPreviewCompetition ? (
    <Box
      width={"100%"}
      zIndex={1}
      backgroundColor={"N700"}
      height={"auto"}
      alignContent={"center"}
      verticalAlign={"middle"}
      alignItems={"center"}
    >
      <Text color={"N100"} width={"100%"} py={2} textAlign={"center"}>
        You are viewing this page in preview mode.
      </Text>
    </Box>
  ) : null;
};
